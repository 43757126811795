<template>
<div class="apps-wrapper top-container fill-height">
    <v-container fluid class="pb-6">
      <div class="heading-container fill-height text-center">
        <div class="heading-message">
          <h1 class="error-title">403</h1>
          <h2>Sorry, you do not have the necessary permissions to access that resource.</h2>
          <h3>Forbidden</h3>
          <div class="mt-6">
            <v-btn to="/">
              Return to homepage
            </v-btn>
          </div>
        </div>
      </div>
  </v-container>
</div>
</template>

<script>
export default {
  name: 'AccessForbidden',
}
</script>

<style scoped lang="scss">
.top-container {
  font-size: 1.4rem;
  padding: 0;
  align-items: start;

  background-color: #071135;
  
  background-image: linear-gradient(20deg, rgba(0, 16, 160, 0.8) 20%, rgba(166, 0, 255, 0.6) 60%), url('~@/assets/404.webp');
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;

  color: white;
}
.error-title {
  font-size: 5rem;
}
.heading-container {
  height: 100vh;

  .heading-message {
    padding-top: 60px;
  }
}
</style>