import Constants from '../constants.js'

import AuthUtil from '../authUtil'

import axios from 'axios'

// TODO add taxonRegion argument to all of these methods and pass that in the body if given. A pikle board can have a deaultTaxonRegion property

export default {
  async getCategoryChildren (categoryId, taxonRegion) {
    const currentUser = AuthUtil.auth.currentUser
    const token = currentUser && (await currentUser.getIdToken())
    const appCheckToken = await AuthUtil.getAppCheckToken()

    let categoryOptions = []
    let categoriesURL = `${Constants.API_BASE_PATH}/regions/${taxonRegion}/categories`
    if (categoryId) {
      categoriesURL += `/${categoryId}`
    }
    const res = await axios.get(categoriesURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    });
    // console.log(`GOT categories response ${JSON.stringify(res.data)}`)
    if (res && res.data) {
      categoryOptions = res.data.categories
      // sort
      categoryOptions.sort((a, b) => {
        return a.order - b.order
      })
    }

    return categoryOptions
  },
  async getUserCategories (taxonRegion) {
    const currentUser = AuthUtil.auth.currentUser
    const token = currentUser && (await currentUser.getIdToken())
    const appCheckToken = await AuthUtil.getAppCheckToken()

    let userCategories = []
    const fetchURL = `${Constants.API_BASE_PATH}/regions/${taxonRegion}/usercategories`
    const res = await axios.get(fetchURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res.data && res.data.status === 'success') {
      userCategories = res.data.categories
    }

    return userCategories
  },
  async getUserSynonymsForCategory (taxonRegion, categoryId, uid) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()

    let userSynonyms = null
    let fetchURL = `${Constants.API_BASE_PATH}/regions/${taxonRegion}/categories/${categoryId}/usersynonyms`
    if (uid) {
      fetchURL = `${fetchURL}?uid=${uid}`
    }
    const res = await axios.get(fetchURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res.data && res.data.status === 'success') {
      userSynonyms = res.data.synonyms
    }

    return userSynonyms
  },
  async deleteUserSynonymForCategory (taxonRegion, categoryId, criteriaId, synonymId, uid) {

    let deleted = false
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()

    // /regions/:region/users/:user/categories/:category/criteria/:criteriaId/synonyms/:synonym
    let deleteURL = `${Constants.API_BASE_PATH}/regions/${taxonRegion}/users/${uid}/categories/${categoryId}/criteria/${criteriaId}/synonyms/${synonymId}`
    const res = await axios.delete(deleteURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res.data && res.data.status === 'success') {
      deleted = true
    }

    return deleted
  },
  async deleteUserNegativeKeywordForCategory (taxonRegion, categoryId, criteriaId, keywordId, uid) {

    let deleted = false
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()

    let deleteURL = `${Constants.API_BASE_PATH}/regions/${taxonRegion}/users/${uid}/categories/${categoryId}/criteria/${criteriaId}/negativekeywords/${keywordId}`
    const res = await axios.delete(deleteURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res.data && res.data.status === 'success') {
      deleted = true
    }

    return deleted
  },
  async getUserNegativeKeywordsForCategory (taxonRegion, categoryId, uid) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()

    let userNegativeKeywords = null
    let fetchURL = `${Constants.API_BASE_PATH}/regions/${taxonRegion}/categories/${categoryId}/negativekeywords`
    if (uid) {
      fetchURL = `${fetchURL}?uid=${uid}`
    }
    const res = await axios.get(fetchURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res.data && res.data.status === 'success') {
      userNegativeKeywords = res.data.negativeKeywords
    }

    return userNegativeKeywords
  },
  // Add user specific negative keyword
  async addUserNegativeKeywordForCategory (taxonRegion, categoryId, criteriaId, keyword, uid) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()

    let postURL = `${Constants.API_BASE_PATH}/regions/${taxonRegion}/categories/${categoryId}/negativekeywords`
    const body = {keyword: keyword, criteriaId: criteriaId}
    if (uid) {
      body.uid = uid
    }
    const res = await axios.post(postURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    return res.data
  },
  async getCategoryCriteria (categoryId, taxonRegion) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    // TODO update to use region specific endpoint
    let criteriaSuggestionsURL = `${Constants.API_BASE_PATH}/regions/${taxonRegion}/categories/${categoryId}/criteria`
    const res = await axios.get(criteriaSuggestionsURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    return res.data
  },
  async getCategoryData (categoryId, taxonRegion) {
    // console.log(`Should lookup category in region ${taxonRegion}`)
    // const token = await AuthUtil.getCurrenUserIdToken()
    // const appCheckToken = await AuthUtil.getAppCheckToken()
    // let categoryDataURL = `${Constants.API_BASE_PATH}/regions/${taxonRegion}/categories/${categoryId}`
    // const res = await axios.get(categoryDataURL, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${token}`,
    // 'X-Firebase-AppCheck': appCheckToken
    //   }
    // })
    // return res.data
    if (taxonRegion && categoryId === 'telescopes' || categoryId === 'laptops'  || categoryId === 'electric-cars') {
      let catName = ''
      if (categoryId === 'telescopes') {
        catName = 'Telescopes'
      } else if (categoryId === 'laptops') {
        catName = 'Laptops'
      } else if (categoryId === 'electric-cars') {
        catName = 'Electric Vehicles'
      }
      return {status: 'success', category: {id: categoryId, name: catName}}
    } else {
      return {error: 'Category not found'}
    }
  },

  async getCriteriaIdFromKeyword (taxon, keyword, taxonRegion) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    // /categories/:category/criteria/match
    // TODO update to user region specific endpoint
    const getURL = `${Constants.API_BASE_PATH}/regions/${taxonRegion}/categories/${taxon}/criteria/match?keyword=${keyword.toLowerCase()}`
    // console.log(`Calling check criteria get ${getURL}`)
    const res = await axios.get(getURL, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data && res.data.status === 'success') {
      return res.data
    } else {
      return null
    }
  },
  async saveValueLearnings (decisionId, payload, taxonRegion) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postURL = `${Constants.API_BASE_PATH}/learn`
    const body = {
      // decisionId: decisionId,
      learnEntries: payload
    }
    if (taxonRegion) {
      body.taxonRegion = taxonRegion
    }
    // console.log(`Calling decision post current list ${JSON.stringify(body)}`)
    return await axios.post(postURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
  },
  async findCategoriesByName (nameStr, taxonRegion) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postURL = `${Constants.API_BASE_PATH}/regions/${taxonRegion}/categories/search`
    const body = {
      nameQuery: nameStr
    }
    let searchResults = []
    // console.log(`Calling decision post current list ${JSON.stringify(body)}`)
    const res = await axios.post(postURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.data) {
      searchResults = res.data.categories
    }

    return searchResults
  },
  async getDecisionMLTrainingDta (decisionId, categoryId) {
    const currentUser = AuthUtil.auth.currentUser
    const token = currentUser && (await currentUser.getIdToken())
    const appCheckToken = await AuthUtil.getAppCheckToken()

    let traindingDocs = []
    const fetchURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/mltraining/${categoryId}`
    const res = await axios.get(fetchURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res.data) {
      traindingDocs = res.data
    }

    return traindingDocs
  },
}
