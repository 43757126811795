// MUTATIONS
export const MUTATION_SET_DECISION                   = 'setDecisionMutation'
export const MUTATION_SET_AUTO_CHECK_OPTION_VALUES   = 'setAutoCheckOptionValues'
export const MUTATION_UPDATE_DECISION                   = 'updateDecisionMutation'
export const MUTATION_SET_DECISION_OPTION_COUNT      = 'setDecisionOptionCountMutation'

export const MUTATION_SET_DECISION_UNSUBSCRIBE_CALLBACK  = 'setDecisionUnsubscribeCallbackMutation'

export const MUTATION_SET_OPTIONS_UNSUBSCRIBE_CALLBACK  = 'setOptionsUnsubscribeCallbackMutation'
export const MUTATION_SET_MEMBERS_UNSUBSCRIBE_CALLBACK  = 'setMembersUnsubscribeCallbackMutation'
export const MUTATION_SET_RESEARCH_DOCS_UNSUBSCRIBE_CALLBACK  = 'setResearchDocsUnsubscribeCallbackMutation'
export const MUTATION_DECISION_OWNER                = 'setDecisionOwnerMutation'
export const MUTATION_CURRENT_TAXON                 = 'setCurrentTaxonMutation'
export const MUTATION_CRITERIA                      = 'setCriteriaMutation'
export const MUTATION_SORT_ASCENDING                = 'setSortAscendingMutation'

export const MUTATION_INCREMENT_PAGE                = 'incrementPageMutation'
export const MUTATION_DECREMENT_PAGE                = 'decrementPageMutation'
export const MUTATION_SET_PAGE                = 'setPageMutation'

export const MUTATION_SET_EXPANSION_PANEL_STATE                   = 'setExpansionPanelState'
export const MUTATION_SET_NEW_CRITERIA_DIALOG_STATE                   = 'setNewCriteriaDialogState'

export const MUTATION_SET_FILTER_ON_HAS_NEW_VALUES  = 'setFilterOnOptionsWithNewValues'
export const MUTATION_SET_FILTER_ON_UNAPPROVED_OPTIONS = 'setFilterOnApprovedOptions'
export const MUTATION_SET_FILTER_ON_REQUIRES_REVIEW = 'setFilterOnRequiresReview'
export const MUTATION_SET_FILTER_ON_MISSING_SUMMARY = 'setFilterOnMissingSummary'

export const MUTATION_SORT_METHOD                   = 'setSortMethodMutation'
export const MUTATION_SORT_CRITERIA                 = 'setSortCriteriaMutation'
export const MUTATION_SET_CRITERIA_RANGES           = 'setCriteriaRangesMutations'
export const MUTATION_CHOSEN_OPTION                 = 'setChosenOptionMutation'
export const MUTATION_SET_CURRENT_LIST                  = 'setCurrentListMutation'
export const MUTATION_RESEARCH_DOCS                 = 'setResearchDocsMutation'
export const MUTATION_OPTIONS                       = 'setOptionsMutation'
export const MUTATION_OPTION_NAME                   = 'setOptionNameMutation'
export const MUTATION_OPTION_IMAGE                   = 'setOptionImageMutation'
export const MUTATION_SET_OPTION_LAST_COMMENT       = 'setOptionLastCommentMutation'
export const MUTATION_SET_OPTION_REASONS            = 'setOptionReasonsMutation'
export const MUTATION_ADD_OPTION_REASON             = 'addOptionReasonMutation'
export const MUTATION_SET_OPTION_REASON_DELETING    = 'setOptionReasonDeletingMutation'
export const MUTATION_DELETE_OPTION_REASON          = 'deleteOptionReasonMutation'
export const MUTATION_DELETE_OPTION_SUMMARY          = 'deleteOptionSummaryMutation'
export const MUTATION_SET_OPTION_SUMMARY            = 'setOptionSummaryMutation'
export const MUTATION_SET_OPTION_APPROVED_SUMMARY            = 'setOptionApprovedSummaryMutation'
export const MUTATION_SET_OPTION_STORES             = 'setOptionStoresMutation'
export const MUTATION_OPTION_NEWLY_ADDED            = 'setOptionNewlyAddedMutation'
export const MUTATION_OPTION_IS_SHORTLISTED         = 'setOptionIsShortlistedMutation'

export const MUTATION_SET_OPTION_MEMBER_VOTE         = 'setOptionMemberVoteMutation'

export const MUTATION_SHORTLIST_SUMMARY                 = 'setShortListSummaryMutation'
export const MUTATION_COMMENTS_COUNT                 = 'setCommentsCount'

export const MUTATION_OPTION_SCRAPE_STATE           = 'setOptionScrapeStateMutation'
export const MUTATION_OPTION_SCRAPE_RESULT          = 'setOptionScrapeResultMutation'
export const MUTATION_OPTION_RE_SCRAPE_RESULT          = 'setOptionReScrapeResultMutation'
export const MUTATION_OPTION_HASH                   = 'setOptionSha256Mutation'
export const MUTATION_OPTION_CRITERIA_VALUES        = 'setOptionCriteriaValuesMutation'
export const MUTATION_OPTION_SCRAPE_DATA            = 'setOptionScrapeDataMutation'
export const MUTATION_OPTION_CLEAR_RESCRAPE_DATA    = 'clearOptionRescrapeDataMutation'
export const MUTATION_OPTION_APPROVED_DATA          = 'setOptionApprovedDataMutation'
export const MUTATION_OPTION_APPROVED_AS_RECOMMENDATION = 'setOptionApprovedAsRecommendation'
export const MUTATION_FETCHING_STATUS               = 'setFetchingStatusMutation'
export const MUTATION_FETCHING_OPTIONS_STATUS               = 'setFetchingOptionsStatusMutation'
// export const MUTATION_UPDATE_OPTION_CHECKED_TIME    = 'setCheckURLTime'
export const MUTATION_UPDATE_CONTENT_STATE          = 'setContentState'
export const MUTATION_ADD_OPTION                    = 'addOptionMutation'
export const MUTATION_REMOVE_OPTION                 = 'removeOptionMutation'
export const MUTATION_UPDATE_OPTION_DATA            = 'updateOptionDataMutation'
export const MUTATION_ADD_RESEARCH_DOC              = 'addResearchDocMutation'
export const MUTATION_REMOVE_RESEARCH_DOC           = 'removeResearchDocMutation'

export const MUTATION_SET_MEMBER                    = 'setDecisionMemberMutation'
export const MUTATION_UNSET_MEMBER                 = 'unsetDecisionMemberMutation'

export const MUTATION_CLEAR_MEMBERS                    = 'clearMembersMutation'
export const MUTATION_ADD_MEMBER                    = 'addMemberMutation'
export const MUTATION_REMOVE_MEMBER                 = 'removeMemberMutation'

export const MUTATION_OPTION_PROS_COUNT          = 'setOptionProsCountMutation'
export const MUTATION_OPTION_CONS_COUNT          = 'setOptionConsCountMutation'

export const MUTATION_ADD_NEW_RECOMMENDATION_ID              = 'addNewRecommendationId'
export const MUTATION_REMOVE_NEW_RECOMMENDATION_ID           = 'removeNewRecommendationId'

export const MUTATION_SET_RECOMMENDATIONS_BOARD_ID              = 'setRecommendationsDecisionId'

export const MUTATION_OPTION_CLEAR_URL              = 'clearOptionURLMutation'

export const MUTATION_OPTION_SUBSTITUTE_FOR          = 'setOptionSubstituteFor'

export const MUTATION_SET_IS_NAVIGATING             = 'setIsNavigating'

export const MUTATION_SET_SHOW_SUBSTITUTES             = 'setShowSubstitutes'

export const MUTATION_SET_VIEWING_AS_PUBLISHED             = 'setViewingAsPublished'

export const MUTATION_UPDATE_OPTION_STORE_URLS                  = 'updateOptionURLsMutation'

export const MUTATION_SET_OPTION_URL                  = 'setOptionURLMutation'

export const MUTATION_SET_SELECTED_OPTION             = 'setSelectedOptionMutation'
export const MUTATION_SET_FULLSCREEN_OPTION_OPEN       = 'setFullscreenOptionDialogMutation'
export const MUTATION_SET_FEATURE_PLUGIN_DIALOG       = 'setFeaturePluginDialogMutation'
export const MUTATION_SET_FEATURE_PLUGIN       = 'setFeaturePluginMutation'

export const MUTATION_SET_SHOW_ALL_CRITERIA             = 'setShowAllCriteriaMutation'

export const MUTATION_SET_SHOW_ALL_COMMENTS             = 'setShowAllComments'

export const MUTATION_SET_FEATURE_DESCRIPTIONS             = 'setFeatureDescriptionsMutation'

export const MUTATION_SET_FEATURE_NOTES             = 'setFeatureNotesMutation'

// ACTIONS
export const ACTION_LOAD_DECISION                      = 'loadDecision'
export const ACTION_LOAD_DECISION_OPTIONS              = 'loadDecisionOptions'
export const ACTION_LOAD_DECISION_MEMBERS              = 'loadDecisionMembers'
export const ACTION_SAVE_CURRENT_LIST                   = 'saveCurrentList'
export const ACTION_UPDATE_CRITERIA                    = 'updateCriteria'
export const ACTION_LOAD_RESEARCH_DOCS                 = 'loadResearchDocs'
export const ACTION_LOAD_OPTIONS                       = 'loadAllOptions'
export const ACTION_SET_OPTION_VOTE                    = 'setOptionVote'
export const ACTION_TOGGLE_OPTION_SHORTLISTED          = 'toggleOptionShortListed'
export const ACTION_TOGGLE_BOARD_VOTING_STATE          = 'toggleDecisionVotingState'
export const ACTION_CREATE_RESEARCH_DOC                = 'createResearchDoc'
export const ACTION_REMOVE_RESEARCH_DOC                = 'removeResearchDoc'
export const ACTION_CREATE_NEW_OPTION                  = 'createNewOption'
export const ACTION_TOGGLE_CHOSEN_OPTION               = 'toggleChosenOption'
export const ACTION_SET_SORT_METHOD                    = 'setSortMethod'
export const ACTION_SET_SORT_CRITERIA                  = 'setSortCriteria'
export const ACTION_TOGGLE_SORT_ASCENDING              = 'toggleSortAscending'
export const ACTION_SET_FILTER_ONLY_CHANGES            = 'toggleFilterOnlyChangedOptions'
export const ACTION_SET_FILTER_UNAPPROVED_OPTIONS      = 'toggleFilterOnlyApprovedOptions'
export const ACTION_SET_FILTER_REQUIRES_REVIEW         = 'toggleFilterOnRequiresReview'
export const ACTION_SET_FILTER_MISSING_SUMMARY         = 'toggleFilterOnMissingSummary'
export const ACTION_LOAD_OPTION_REASONS                = 'loadOptionReasons'
export const ACTION_LOAD_OPTION_LAST_COMMENT                = 'loadOptionLastComment'
export const ACTION_CREATE_OPTION_REASON               = 'createOptionReason'
export const ACTION_DELETE_OPTION_REASON               = 'deleteOptionReason'
export const ACTION_SAVE_OPTION_CRITERIA               = 'saveOptionCriteria'
export const ACTION_DELETE_OPTION_SUMMARY               = 'deleteOptionSummary'

export const ACTION_UPDATE_OPTION_URLS               = 'updateOptionURLs'

export const ACTION_SET_OPTION_URL               = 'setOptionURL'

export const ACTION_DELETE_OPTION               = 'deleteOption'

export const ACTION_LOAD_OPTION_STORES                = 'loadOptionStores'

export const ACTION_CONVERT_OPTION_TO_GROUP           = 'convertOptionToGroup'
