// NOTE: R.B we are choosing to load firebase auth using initializeAuth isntead of getAuth because of performance issues on mobile with auths iframe.
// See... https://github.com/firebase/firebase-js-sdk/issues/4946#issuecomment-1163262324 and https://firebase.google.com/docs/auth/web/custom-dependencies
// The side effect of this is that we do not automatically load dependencies which support signInWithPopup or signInWithRedirect
// This is why it is faster and the iframe is not loaded. If we need these in the future refer to the links above for how to use those.
import {initializeAuth, indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence} from 'firebase/auth';

import { getToken, initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

const AUTH_UTIL = {
  auth: null,
  appCheck: null,
  init: function (app) {
    AUTH_UTIL.auth = initializeAuth(app, {
      persistence: [
        indexedDBLocalPersistence,
        browserLocalPersistence,
        browserSessionPersistence
      ],
    });

    AUTH_UTIL.initAppCheck(app)
  },
  initAppCheck: function (app) {
    // Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
    // site key and pass it to initializeAppCheck().
    if (!AUTH_UTIL.appCheck && process.env.VUE_APP_RECAPTCHA_ENTERPRISE_KEY) {
      // console.log(`Init ReCaptchaEnterpriseProvider`)

      // Init debug provider if we are in dev
      if (process.env.NODE_ENV === 'development') {
        self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
      }

      AUTH_UTIL.appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaEnterpriseProvider(process.env.VUE_APP_RECAPTCHA_ENTERPRISE_KEY),
        isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
      });
    }
  },
  getCurrenUserIdToken: async function () {
    return AUTH_UTIL.auth.currentUser && (await AUTH_UTIL.auth.currentUser.getIdToken())
  },
  getAppCheckToken: async function () {
    try {
      let appCheckToken;
      if (AUTH_UTIL.appCheck) {
        const getTokenResponse = await getToken(AUTH_UTIL.appCheck, /* forceRefresh= */ false);
        if (getTokenResponse) {
          appCheckToken = getTokenResponse.token
        }
      }
      return appCheckToken
    } catch (err) {
        // Handle any errors if the token was not retrieved.
        // console.log(`Error getting app check token ${err}`)
        return;
    }
  }
}

export default AUTH_UTIL
