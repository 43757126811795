<template>
  <v-app>
    <AppToolbar v-if="$route.meta && $route.meta.showAppBar" />
    <v-main>
      <!-- <v-fade-transition mode="in-out"> -->
        <router-view/>
      <!-- </v-fade-transition> -->
    </v-main>
  </v-app>
</template>

<script>
import AppToolbar from '@/components/Navigation/AppToolbar'

export default {
  name: 'App',
  components: {
    AppToolbar
  },
}
</script>

<style>
#app {
  font-family: 'Roboto', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body.pikle-plugins #app {
  background: none;
  background-color: transparent;
}
</style>
