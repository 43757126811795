import Constants from '../constants.js'
import AuthUtil from '../authUtil'

import axios from 'axios'

export default {
  async decisionMessages (decisionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const assistantChatURL = `${Constants.ASSISTANT_API_BASE_PATH}/decisions/${decisionId}/messages`

    const res = await axios.get(assistantChatURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async decisionChat (decisionId, message, userName, optionNames) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const assistantChatURL = `${Constants.ASSISTANT_API_BASE_PATH}/decisions/${decisionId}/chat`

    const payload = {
      message: message,
      userName: userName
    }
    if (optionNames && optionNames.length > 0) {
      payload.optionNames = optionNames
    }

    const res = await axios.post(assistantChatURL, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async optionChat (decisionId, optionId, message, userName, optionNames) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const assistantChatURL = `${Constants.ASSISTANT_API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/chat`

    const payload = {
      message: message,
      userName: userName
    }
    if (optionNames && optionNames.length > 0) {
      payload.optionNames = optionNames
    }

    const res = await axios.post(assistantChatURL, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async decisionFeatureSuggestions (decisionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const assistantChatURL = `${Constants.ASSISTANT_API_BASE_PATH}/decisions/${decisionId}/featuresuggestions`

    const res = await axios.post(assistantChatURL, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async optionFeatureValueSuggestions (decisionId, optionId, criteria) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const assistantChatURL = `${Constants.ASSISTANT_API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/valuesuggestions`

    const res = await axios.post(assistantChatURL, {criteria: criteria}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
}
