const CONSTANTS = {
    API_BASE_PATH: process.env.VUE_APP_API_BASE_PATH,
    ASSISTANT_API_BASE_PATH: process.env.VUE_APP_ASSISTANT_API_BASE_PATH,
    JSSDK_API_BASE_PATH: process.env.VUE_APP_JSSDK_API_BASE_PATH,
    DEVELOPER_DOMAIN: process.env.VUE_APP_DEVELOPER_DOMAIN,
    ECOMMERCE_DOMAIN: process.env.VUE_APP_ECOMMERCE_DOMAIN,
    SCRAPE_STATES:  {
      unscraped: 0,
      scraped: 1,
      approved: 2,
      validating: 3,
      scraping: 4,
      failed: 5
    },
    DEFAULT_ENTITY_LIMITS: {
      pikles: 10,
      piklesPerCategory: 2,
      options: 20,
      stores: 5,
      members: 10,
      features: 20
    },
    PIKLE_ENV: process.env.VUE_APP_PIKLE_ENV
}

export default CONSTANTS
