// MUTATIONS
export const MUTATION_EXP_FEATURES                    = 'setShowExperimentalFeatures'
export const MUTATION_SET_DEVELOPER_ACCESS            = 'setDeveloperAccess'
export const MUTATION_HIDE_INTRO                      = 'setHideIntro'
export const MUTATION_SET_LOADED                      = 'setLoaded'
export const MUTATION_SET_LOADING                      = 'setLoading'
export const MUTATION_SET_IS_ANONYMOUS               = 'setIsAnonymous'
export const MUTATION_SET_USER_NAME               = 'setUserName'
export const MUTATION_SET_META_DATA                  = 'setMetaData'
export const MUTATION_SET_CURRENT_TAXON_REGION        = 'setCurrentTaxonRegion'
export const MUTATION_SET_LAST_DECISION               = 'lastDecision'

// export const MUTATION_SET_CURRENT_GEO_POSITION          = 'currentGeoPositionMutation'
export const MUTATION_SET_CURRENT_COUNTRY               = 'currentCountryMutation'
export const MUTATION_SET_CURRENT_REGION               = 'currentRegionMutation'

// ACTIONS
export const ACTION_LOAD_PREFERENCES                    = 'loadPreferences'
export const ACTION_SET_LAST_DECISION                    = 'setLastDecision'
export const ACTION_SET_HIDE_INTRO                    = 'updateHideIntro'
export const ACTION_SET_USERS_LOCATION               = 'setUsersLocationPref'
export const ACTION_HANDLE_SIGN_IN                        = 'handleCurrentUserSignIn'
