import Constants from '../constants.js'

import AuthUtil from '../authUtil'

import axios from 'axios'

export default {
  async getPreferences () {
    const currentUser = AuthUtil.auth.currentUser
    const token = currentUser && (await currentUser.getIdToken())
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const preferencesURL = `${Constants.API_BASE_PATH}/user/preferences`
    const response = await axios.get(preferencesURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response) {
      const metaData = {
        uid: currentUser.uid,
        displayName: currentUser.displayName,
        email: currentUser.email,
        photoUrl: currentUser.photoUrl,
        apps: response.data.apps
      }
      // AuthUtil.auth.currentUser.displayName
      return {metaData: metaData, preferences: response.data}
    } else {
      return null
    }
  },
  async getUsersPublicDetails (uid) {
    const currentUser = AuthUtil.auth.currentUser
    const token = currentUser && (await currentUser.getIdToken())
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const userURL = `${Constants.API_BASE_PATH}/users/${uid}/public`
    const response = await axios.get(userURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response) {
      return response.data
    } else {
      return null
    }
  },
  async getUIDFromNameSlug (nameSlug) {
    const currentUser = AuthUtil.auth.currentUser
    const token = currentUser && (await currentUser.getIdToken())
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const userURL = `${Constants.API_BASE_PATH}/users/${nameSlug}/uid`
    const response = await axios.get(userURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response) {
      return response.data
    } else {
      return null
    }
  },
  async getCustomToken () {
    const currentUser = AuthUtil.auth.currentUser
    const token = currentUser && (await currentUser.getIdToken())
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const newTokenURL = `${Constants.API_BASE_PATH}/user/token`
    const response = await axios.get(newTokenURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data.token
    } else {
      return null
    }
  },
  async setHideIntroTour (hideIntro) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postURL = `${Constants.API_BASE_PATH}/user/preferences`
    const response = await axios.post(postURL, {hideIntro: hideIntro}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.status === 200 && response.data && response.data.status === 'success') {
      return true
    } else {
      return false
    }

  },
  async setLastDecision (lastDecision) {
    if (lastDecision !== null && lastDecision.owner !== AuthUtil.auth.currentUser.uid) {
      return false
    }
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postURL = `${Constants.API_BASE_PATH}/user/preferences`
    const newDecisionData = lastDecision ? {id: lastDecision.id, taxon: lastDecision.taxon} : null;
    const response = await axios.post(postURL, {lastDecision: newDecisionData}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.status === 200 && response.data && response.data.status === 'success') {
      return true
    } else {
      return false
    }

  },
  async setUserLocation (userLocation) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postURL = `${Constants.API_BASE_PATH}/user/preferences`
    const locationData = {country: userLocation.country}
    if (userLocation.region) {
      locationData.region = userLocation.region
    }
    const response = await axios.post(postURL, locationData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.status === 200 && response.data && response.data.status === 'success') {
      return true
    } else {
      return false
    }

  },
  async registerAlphaUser (registerDetails) {
    const contactEmail = registerDetails.email
    const storeURL = registerDetails.storeURL
    const firstName = registerDetails.firstName
    const lastName = registerDetails.lastName
    const region = registerDetails.region

    let postRegisterAlphaURL = `${Constants.API_BASE_PATH}/users/register/alpha`
    if (storeURL) {
      postRegisterAlphaURL = `${Constants.API_BASE_PATH}/users/register/ecommerce/alpha`
    }
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const body = {
      email: contactEmail
    }
    if (storeURL) {
      body.storeURL = storeURL
    }
    if (firstName) {
      body.firstName = firstName
    }
    if (lastName) {
      body.lastName = lastName
    }
    if (region) {
      body.region = region
    }

    // console.log(`Calling post register user ${JSON.stringify(body)}`)
    const registerResponse = await axios.post(postRegisterAlphaURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (registerResponse && registerResponse.data && registerResponse.data.status) {
      return registerResponse.data.status
    } else {
      return null
    }
  },
  async getGeoLocation () {
    const preferencesURL = `${Constants.API_BASE_PATH}/user/geolocation`
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const response = await axios.get(preferencesURL, {
      headers: {
        'Content-Type': 'application/json',
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      let geoLocationData = null
      if (response.data.country) {
        geoLocationData = response.data
      }
      // AuthUtil.auth.currentUser.displayName
      return geoLocationData
    } else {
      return null
    }
  },
  async recordSignIn () {
    const currentUser = AuthUtil.auth.currentUser
    const token = currentUser && (await currentUser.getIdToken())
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const startSessionURL = `${Constants.API_BASE_PATH}/users/${currentUser.uid}/startsession`
    return await axios.post(startSessionURL, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
  },
  async recordUsageStats (uid, eventId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUsageStatsEndpoint = `${Constants.API_BASE_PATH}/user/${uid}/stats`

    const body = {
      source: 'www.pikle.online',
      eventId: eventId
    }

    await axios.post(postUsageStatsEndpoint, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
  }
}
