<template>
  <v-container
    fluid
    class="page-container fill-height d-flex flex-column justify-start"
  >
    <v-container class="d-flex justify-center mx-2 page-top-bar">
      <div class="user-menu-section">
        <div v-if="userPrefsLoading">
          <v-progress-circular
            :size="16"
            :width="2"
            indeterminate
            class="mr-6"
          ></v-progress-circular>
        </div>
        <div v-else-if="userPrefsLoaded" class="d-flex align-center">
          <v-btn
            outlined
            small
            color="primary"
            @click="gotToBoards"
            class="d-none d-md-block"
          >
            Your boards
          </v-btn>
          <UserMenu showBoards />
        </div>
      </div>
      <div>
        <div class="mb-6 d-flex justify-center">
          <v-img
            v-if="showProduction"
            alt="Pikle Logo"
            :src="require('../assets/logo-full.svg')"
            max-width="110"
          ></v-img>
          <v-img
            v-else
            alt="Pikle Logo"
            :src="require('../assets/logo-text.svg')"
            max-width="85"
          ></v-img>
        </div>
        <v-tabs hide-slider :show-arrows="false" centered v-model="tab">
          <v-tab><div><div class="d-block d-md-inline-block">For</div> Everyone</div></v-tab>
          <v-tab><div><div class="d-block d-md-inline-block">For</div> Ecommerce</div></v-tab>
        </v-tabs>
      </div>
    </v-container>
    <div class="flex-grow-1 d-flex align-center">
      <v-tabs-items v-model="tab" id="homepage-tab-items">
        <v-tab-item transition="fade-transition hide-on-leave" class="for-everyone">
          <div v-if="showProduction">
            <div>
              <div class="d-flex justify-center d-md-block mt-6">
                <div class="d-flex flex-column flex-md-row justify-space-between">
                  <div class="tagline-wrapper large mt-6 text-md-left">
                    <!-- <div class="tagline-text tag-line-everyone">
                      Add any page to a shareable decision board. With friends and a personal assistant you can pick the best!
                    </div> -->
                    <div class="tagline-text tag-line-everyone">
                      The smart, shareable decision board. With friends and a personal assistant, you can be sure to pick the best!
                    </div>
                  </div>
                  <div class="pa-0 mt-12 mt-md-0 px-md-6">
                    <img :src="require('@/assets/homepage/pikleboard.webp')" class="title-image">
                  </div>
                </div>
              </div>
              <div>
                <div class="d-flex justify-center text-md-left">
                  <div class="mission-statement text-h4 grey-darken1--text">
                    <p>
                      We are on a mission to make those difficult decisions easy.
                    </p>
                    <div class="d-flex justify-center pa-0 my-6 mt-md-0 px-md-6">
                      <img :src="require('@/assets/homepage/surrounded.webp')" class="section-image">
                    </div>
                    <p>
                      Instead of dealing with tabs and pages, use Pikle to keep track of your options. You can filter out noise and bring everyone together to learn what really matters for your choice.
                    </p>
                    <div class="d-flex justify-center pa-0 my-6 mt-md-0 px-md-6">
                      <img :src="require('@/assets/homepage/decided.webp')" class="section-image">
                    </div>
                    <p>
                      Making the best choice can be a joyful and collaborative experience! One that you are fully in charge of.
                    </p>
                  </div>
                </div>
              </div>
              <div class="info-section">
                <div class="d-flex justify-center mt-6">
                  <div class="text-h5 grey-darken1--text">Pikle is in beta. Register your interest to join our beta program.</div>
                </div>
                <div class="mt-6">
                  <v-btn to="/register/beta" class="primary-action-btn mb-6" x-large color="primary">
                    Register
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-else :class="titleClass">
            <div>COMING SOON</div>
          </div> -->
        </v-tab-item>
        <v-tab-item transition="fade-transition hide-on-leave">
          <div v-if="showProduction">
            <div class="d-flex justify-center d-md-block mt-6">
              <div class="d-flex flex-column flex-md-row justify-space-between">
                <div class="tagline-wrapper mt-6 text-md-left">
                  <div class="tagline-text tag-line-ecommerce">
                    Discover your AI-powered product catalog
                  </div>
                </div>
                <div class="pa-0 mt-12 mt-md-0 px-md-6">
                  <img :src="require('@/assets/homepage/aibox.webp')" class="title-image">
                </div>
              </div>
            </div>
            <!-- <div>
              <v-btn href="https://www.pikle.ai" target="_blank" class="primary-action-btn mb-6" x-large color="primary">
                Learn More
              </v-btn>
            </div> -->
            <div class="my-8 ecommerce-description text-h4 grey-darken1--text">
              <!-- Unlock the hidden data in your content and help your customers find and buy the right products -->
              Unlock the data hidden in your existing content and empower your customers & business with Pikle
            </div>
          </div>
          <div v-if="showProduction" class="d-block">
            <div class="info-section">
              <div class="section-container pb-md-6 pt-10">
                  <img :src="require('@/assets/homepage/EcommerceOverview.webp')" class="overview-image">
              </div>
            </div>
            <div class="mt-6">
              <v-btn href="https://www.pikle.ai" target="_blank" class="primary-action-btn mb-6" x-large color="primary">
                Learn More
              </v-btn>
            </div>
          </div>
          <!-- <div v-else :class="titleClass" class="ecommerce">
            <div>COMING SOONER</div>
          </div> -->
        </v-tab-item>
      </v-tabs-items>
    </div>
    <footer class="pa-6" v-if="showProduction">
      <div class="d-flex justify-center align-md-start grey--text text-caption py-6 flex-column flex-md-row">
        <div class="ma-6">
          <a href="/privacy" target="_blank" class="font-weight-light grey--text pa-1 rounded  text-uppercase text-decoration-none">
            Privacy Statement
          </a>
        </div>
        <div class="ma-6">
          <a href="/terms" target="_blank" class="font-weight-light grey--text pa-1 rounded text-uppercase text-decoration-none">
            Terms of Service
          </a>
        </div>
        <div class="ma-6">
          © 2024 www.pikle.online
        </div>
      </div>
    </footer>
  </v-container>
</template>

<script>

import AuthUtil from '../authUtil'

import { mapState } from 'vuex'

import UserMenu from '@/components/Navigation/UserMenu'

import Constants from '../constants.js'

export default {
  name: 'PikleHome',
  components: {
    UserMenu
  },
  data () {
    return {
      tab: null,
      showProduction: false
    }
  },
  mounted: function () {
    // this.showProduction = this.$route.query['production'] && !this.inStaging
    this.showProduction = !this.inStaging
  },
  computed: {
    ...mapState({
      developerAccess: state => state.user.developerAccess,
      userPrefsLoaded: state => state.user.loaded,
      userPrefsLoading: state => state.user.loading
    }),
    userStateKnown () {
      let res = true
      if (AuthUtil.auth.currentUsercurrentUser) {
        res = this.userPrefsLoaded && !this.userPrefsLoading
      }
      return res
    },
    titleClass () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'coming-soon-title-sm'
        case 'sm': return 'coming-soon-title-sm'
        case 'md': return 'coming-soon-title-sm'
        case 'lg': return 'coming-soon-title'
        case 'xl': return 'coming-soon-title'
        default: return 'coming-soon-title'
      }
    },
    inStaging () {
      return Constants.PIKLE_ENV && Constants.PIKLE_ENV === 'staging'
    },
  },
  methods: {
    gotToBoards: function() {
      this.$router.push('/pikles')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#homepage-tab-items {
  background-color: transparent !important;

  // .for-everyone {
  //   background-color: #FFF !important;
  // }
}

.info-section {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
  min-height: 400px;
}

.info-section h2 {
  /* font-size: 2.6rem; */
  font-size: clamp(1.8rem, 4vw, 2.4rem);
  font-weight: 700;
}

.tagline-wrapper {
  max-width: 400px;
}

.tagline-wrapper.large {
  max-width: 450px;
}

.tagline-text {
  font-family: 'Libre Franklin', sans-serif;
  display: inline;
  line-height: 1.3 !important;
  font-size: clamp(2.2rem, 4vw, 3.3rem);
  // text-shadow: 0 0px 20px rgb(45, 39, 53);
}

.title-highlight {
  padding: 0px 8px;
  background-color: #28002f8a;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  text-shadow: none !important;
}

.subheading-text {
  text-shadow: 0 3px 10px rgb(45, 39, 53);
}

.mission-statement {
  max-width: 900px;
  margin-top: 100px;
}

.ecommerce-description {
  max-width: 900px;
  margin-top: 100px;
}

.page-container {
  background: #FFF;
  padding: 0px 20px;

  .coming-soon-title {
    font-size: 3.2rem;
    color: #6290fc;
    // letter-spacing: 1rem;
    padding: 10px;
    // max-width: 675px;

    &.ecommerce {
      background: #6290fc;
      color: #FFF;
    }
  }

  .coming-soon-title-sm {
    font-size: 2rem;
    color: #6290fc;
    // letter-spacing: 1rem;
    padding: 10px;
    // max-width: 675px;

    &.ecommerce {
      background: #6290fc;
      color: #FFF;
    }
  }

  .tag-line-everyone {
    max-width: 700px;
    background-image: linear-gradient(135deg, #9118dc, #6290fc);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: clamp(1.8rem, 4vw, 2.4rem);
  }
  .tag-line-ecommerce {
    max-width: 700px;
    background-image: linear-gradient(135deg, #9118dc, #6290fc);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  .user-menu-section {
    position: absolute;
    right: 10px;
    top: 18px;
  }

  .page-top-bar {
    position: relative;
  }

  .overview-image {
    max-width: 650px;
    width: 100%;
  }

  .title-image {
    max-width: 350px;
    width: 90%;
  }

  .section-image {
    max-width: 300px;
    width: 65%;
  }

  footer {
    width: 100%;
    border-top: 1px solid #d5d9dd80;
    a:hover {
      background-color: #cbd5e180;
      color: black !important;
    }
  }
}
</style>
