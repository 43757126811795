import { getFirestore } from "firebase/firestore";

const FIRESTORE_UTIL = {
  db: null,
  init: function (app) {
    FIRESTORE_UTIL.db = getFirestore(app);
  }
}

export default FIRESTORE_UTIL
