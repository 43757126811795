// MUTATIONS
export const MUTATION_NEW_DECISION_DIALOG               = 'newDecisionDialogMutation'
export const MUTATION_SET_NEW_DECISION_LINKED_OPTION    = 'newDecisionLinkedOptionMutation'
export const MUTATION_SET_DECISIONS                     = 'setDecisionsMutation'
export const MUTATION_ADD_DECISION                      = 'addDecisionMutation'
export const MUTATION_REMOVE_DECISION                   = 'removeDecisionMutation'
export const MUTATION_UPDATE_DECISION                   = 'updateDecisionMutation'
export const MUTATION_FETCHING_STATUS                   = 'setFetchingStatusMutation'
export const MUTATION_SET_UNSUBSCRIBE_CALLBACK          = 'setDataUnsubscribeCallback'
export const MUTATION_SET_SHARED_UNSUBSCRIBE_CALLBACK          = 'setSharedDataUnsubscribeCallback'

export const MUTATION_LOADING_USERS_DECISIONS                   = 'setLoadingUsersDecision'
export const MUTATION_LOADING_SHARED_DECISIONS                   = 'setLoadingShareDecisions'

export const MUTATION_SET_INVITATIONS                   = 'setInvitationsList'

export const MUTATION_ADD_SHARED_OWNER                   = 'addShareOwnerMutation'

// ACTIONS
export const ACTION_LOAD_DECISIONS                      = 'loadDecisions'
export const ACTION_LOAD_SHARED_DECISIONS               = 'loadSharedDecisions'
export const ACTION_LOAD_DECISIONS_MAP                  = 'loadDecisionsMap'
export const ACTION_CREATE_NEW_DECISION                 = 'createNewDecision'
export const ACTION_LOAD_INVITATIONS                      = 'loadDecisionInvitations'
export const ACTION_HANDLE_INVITATION_RESPONSE          = 'handleInvitationResponse'
