// src/vuetify/theme.js

// import colors from 'vuetify/lib/util/colors'

export default {
  primary: `#00b7fc`,
  secondary: `#c44bc4`,
  accent: `#ff9800`,
  error: `#f44336`,
  warning: `#ffc107`,
  info: `#607d8b`,
  success: `#4caf50`
}
