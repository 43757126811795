import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import light from './vuetify/app-theme'
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: { light }
  },
});
