import Constants from '../../constants.js'
import userAPI from '../../api/user'
import * as types from '../types/user'
import * as commonTypes from '../types/common'

// scrapingRegions.country = ISO 3166-1 alpha-2 country code https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
export const getDefaultState = () => ({
  loaded: false,
  loading: false,
  hideIntro: null,
  developerAccess: false,
  userName: null,
  isAnonymous: null,
  showExperimentalFeatures: false,
  currentTaxonRegion: null,//'australasia',
  lastDecision: null,
  currentCountry: null,
  currentRegion: null,
  entityLimits: null,
  // currentGeoPosition: null,
  metaData: null,
  scrapingRegions: [
    {id: 'au', name: 'Australia', country: 'AU', scrapingRegion: 'au'},
    {id: 'nz', name: 'New Zealand', country: 'NZ', scrapingRegion: 'nz'},
    {id: 'us', name: 'USA', country: 'US', scrapingRegion: 'us'},
    // {id: 'nl', name: 'The Netherlands', country: 'NL', scrapingRegion: 'nl'}
  ]
})

// initial state
const state = getDefaultState()

// const getCoordinates = () => {
//   return new Promise(function(resolve, reject) {
//     navigator.geolocation.getCurrentPosition(
//             position => resolve(position),
//             error => reject(error)
//         )
//   });
// }

// getters
const getters = {
  userEntityLimits: state => {
    return state.entityLimits || Constants.DEFAULT_ENTITY_LIMITS
  },
  userIsSysAdmin: state => {
    return state.metaData ? state.metaData.isSysAdmin : false
  }
}

// actions
const actions = {
  [commonTypes.ACTION_RESET_STATE]: ({ commit }) => {
    commit(commonTypes.MUTATION_RESET_STATE)
  },
  [types.ACTION_LOAD_PREFERENCES]: async ({ state, commit }) => {
    commit(types.MUTATION_SET_LOADED, false)
    commit(types.MUTATION_SET_LOADING, true)
    const response = await userAPI.getPreferences()

    let userPrefs = null
    if (response && response.preferences) {
      userPrefs = response.preferences
      const shouldHideIntro = response.preferences.hideIntro || false
      const isAnonymous = response.preferences.isAnonymous || false
      const isSysAdmin = response.preferences.isSysAdmin || false
      const taxonRegion = response.preferences.defaultTaxonRegion || null
      const hasDeveloperAccess = response.preferences.developerAccess || false
      const userName = response.preferences.userName || null
      const nameSlug = response.preferences.nameSlug || null

      const currentCountryCode = response.preferences.country || null
      const currentRegionCode = response.preferences.region || null

      const lastDecision = response.preferences.lastDecision || null

      commit(types.MUTATION_SET_DEVELOPER_ACCESS, hasDeveloperAccess)

      commit(types.MUTATION_HIDE_INTRO, shouldHideIntro)
      commit(types.MUTATION_SET_IS_ANONYMOUS, isAnonymous)

      commit(types.MUTATION_SET_LAST_DECISION, lastDecision)

      if (taxonRegion) {
        commit(types.MUTATION_SET_CURRENT_TAXON_REGION, taxonRegion)
      }

      if (userName) {
        commit(types.MUTATION_SET_USER_NAME, userName)
      }

      if (currentCountryCode) {
        commit(types.MUTATION_SET_CURRENT_COUNTRY, currentCountryCode)
        const scrapingRegion = state.scrapingRegions.find(next => next.country === currentCountryCode)
        if (scrapingRegion) {
          userPrefs.regionSupported = true
        }
      } else {
        userPrefs.regionSupported = false
      }

      if (currentRegionCode) {
        commit(types.MUTATION_SET_CURRENT_REGION, currentRegionCode)
      }

      commit(types.MUTATION_SET_LOADED, true)
      if (response.metaData) {
        commit(types.MUTATION_SET_META_DATA, {...response.metaData, isSysAdmin: isSysAdmin, nameSlug: nameSlug})
      }
    }

    commit(types.MUTATION_SET_LOADING, false)
    return userPrefs

    // let ipLookupRequired = false
    // let locationPermissionGranted = false
    // if ("geolocation" in navigator) {
    //   const locationPermissionStatus = await navigator.permissions.query({name: 'geolocation'})
    //   console.log(`Got locationPermissionStatus = ${locationPermissionStatus.state}`)
    //   locationPermissionGranted = locationPermissionStatus && 'granted' === locationPermissionStatus.state
    //   ipLookupRequired = locationPermissionStatus && 'denied' === locationPermissionStatus.state
    // } else {
    //   ipLookupRequired = true
    // }
    // console.log(`About to check geoloction ipLookupRequired = ${ipLookupRequired}`)
    // if (!ipLookupRequired && locationPermissionGranted) {
    //   // get position
    //   try {
    //     const pos = await getCoordinates()
    //     console.log(pos)
    //     if (pos) {
    //       console.log(pos)
    //       commit(types.MUTATION_SET_CURRENT_GEO_POSITION, {latitude: pos.coords.latitude, longitude: pos.coords.longitude} )
    //       // userPrefs.location = {latitude: pos.coords.latitude, longitude: pos.coords.longitude}
    //     }
    //   } catch (err) {
    //     console.log(`Error get location`)
    //     ipLookupRequired = true
    //   }
    // }
    //
    // if (ipLookupRequired)
    // {
    //   console.log('should lookup ip')
    //   // TODO
    //   commit(types.MUTATION_SET_CURRENT_GEO_POSITION, {latitude: 0, longitude: 0} )
    //   // userPrefs.location = {latitude: 0, longitude: 0}
    // }
  },
  [types.ACTION_SET_LAST_DECISION]: ({ commit }, decision) => {
    const newDecisionRef = decision ? {id: decision.id, taxon: decision.taxon} : null;
    userAPI.setLastDecision(newDecisionRef).then(() => {
      commit(types.MUTATION_SET_LAST_DECISION, newDecisionRef)
    })
  },
  [types.ACTION_SET_USERS_LOCATION]: async ({ dispatch }, payload) => {
    if (!payload.country) return;

    const success = await userAPI.setUserLocation(payload)
    if (success) {
      // Reload the users preferences as some prefs like scraper region depend on the country which has now changed
      dispatch(types.ACTION_LOAD_PREFERENCES)
    }
    return success
  },
  [types.ACTION_SET_HIDE_INTRO]: ({ state, commit }, shouldHideIntro) => {
    // console.log(`Toggling short listed`)
    if (state.hideIntro !== shouldHideIntro) {
      // console.log(`Option ${optionId} current shortlisted state = ${theOption.shortListed}`)
      // Commit the mutation before updating the DB so we get immediate response in the UI, will revert if it fails
      commit(types.MUTATION_HIDE_INTRO, shouldHideIntro)
      userAPI.setHideIntroTour(shouldHideIntro).then(success => {
        if (!success) {
          commit(types.MUTATION_HIDE_INTRO, !shouldHideIntro)
        }
      })
    }
  },
  [types.ACTION_HANDLE_SIGN_IN]: async ({ dispatch }) => {
    // Load users prefs
    const prefsResponse = await dispatch(types.ACTION_LOAD_PREFERENCES)
    // Record the login. This will indicate the refresh tokens are new and can be expired.
    await userAPI.recordSignIn()

    return prefsResponse
  }
}

// mutations
const mutations = {
  // Reset to the default state
  [commonTypes.MUTATION_RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [types.MUTATION_EXP_FEATURES] (state, shouldShowExperimentalFeatures) {
    state.showExperimentalFeatures = shouldShowExperimentalFeatures
  },
  [types.MUTATION_SET_DEVELOPER_ACCESS] (state, hasDeveloperAccess) {
    state.developerAccess = hasDeveloperAccess
  },
  [types.MUTATION_SET_CURRENT_TAXON_REGION] (state, newTaxonRegion) {
    state.currentTaxonRegion = newTaxonRegion
  },
  [types.MUTATION_HIDE_INTRO] (state, newVal) {
    state.hideIntro = newVal
  },
  [types.MUTATION_SET_IS_ANONYMOUS] (state, newVal) {
    state.isAnonymous = newVal
  },
  [types.MUTATION_SET_USER_NAME] (state, newVal) {
    state.userName = newVal
  },
  [types.MUTATION_SET_META_DATA] (state, newVal) {
    state.metaData = newVal
  },
  [types.MUTATION_SET_LOADED] (state, newVal) {
    state.loaded = newVal
  },
  [types.MUTATION_SET_LOADING] (state, newVal) {
    state.loading = newVal
  },
  [types.MUTATION_SET_LAST_DECISION] (state, lastDecision) {
    state.lastDecision = lastDecision
  },
  [types.MUTATION_SET_CURRENT_COUNTRY] (state, countryCode) {
    state.currentCountry = countryCode
  },
  [types.MUTATION_SET_CURRENT_REGION] (state, regionCode) {
    state.currentRegion = regionCode
  },
  // [types.MUTATION_SET_CURRENT_GEO_POSITION] (state, geoPosition) {
  //   state.currentGeoPosition = geoPosition
  // },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
