import axios from 'axios'
import router from '@/router'

axios.interceptors.response.use((resp) => {
  if(resp.config.method == 'get') {
    const latestVersion = resp.headers['pikle-version'] || 'default'
    const localVersion = localStorage.getItem('@pikle/client/version')
    // console.log(`Checking latest ${latestVersion} against local ${localVersion}`)
    // console.log(`Headers =  ${JSON.stringify(resp.headers)}`)
    if(latestVersion !== localVersion) {
      localStorage.setItem('@pikle/client/version', latestVersion)
      // console.log('Should reload')
      if (localVersion) {
        // Reload the page if there was an existing local record of the version
        // We do not want to reload the first time someone uses the app.
        window.location.reload() // Reload on any get that has an uknown / new version
      }
    }
  }
  return Promise.resolve(resp)
},
err => {
  let path = null;
  switch (err.response.status) {
    case 403: path = '/forbidden'; break;
    // case 500: path = '/error'; break;
  }
  if (path) {
    router.push(path);
  }
  throw err;
})