<template>
  <v-app id="pikle">
    <v-main>
      <v-container
        fluid
      >
        <v-row align="center" justify="center">
          <v-col
            cols="10"
            class="text-left"
          >
            <div class="terms-of-service">
              <h1>Terms of Service</h1>

            <p>
              We (the folks at Pikle.online) are on a mission to make difficult decisions easier to make by allowing anyone to filter out the noise from the content they are considering, on the web and beyond, so that learning about and choosing things online can be a joyfull and collaborative experience. We hope you love our products and services as much as we love creating them.
            </p>
            <p>
              These Terms of Service (“Terms”) describe our commitments to you, and your rights and responsibilities when using our services. Please read them carefully and reach out to us if you have any questions.
            </p>
            <p>
              Pikle is a business division of R A Blankenbyl Enterpises Limited based in Auckland, New Zealand.
            </p>
            <p>
              Terms of Service
            </p>
            <p>
              These Terms govern your access to and use of Pikle.online as well as all content and Pikle products and services available at or through these websites and apps (collectively, “Services”).
            </p>
            <p>
              Our Services are offered subject to your acceptance, without modification, of all of the terms and conditions contained herein and all other operating rules, policies (including, without limitation, Pikle's Privacy Policy), and procedures that may be published from time to time by Pikle (collectively, the “Agreement”). You agree that we may automatically upgrade our Services, and the Agreement will apply to any upgrades.
            </p>
            <p>
              Please read the Agreement carefully before accessing or using our Services. By accessing or using any part of our Services, you agree to become bound by the Agreement. If you do not agree to all the terms of the Agreement, then you may not access or use our Services.
            </p>
            <p>
              1. Who’s Who
            </p>
            <p>
              Throughout these Terms, “you” applies to both individuals and entities that access or use our Services. If you are an individual using our Services on behalf of an entity, you represent and warrant that you have the authority to bind that entity to the Agreement and that by using our Service(s), you are accepting the Agreement on behalf of that entity. Your Agreement is with Pikle as a division of R A Blankenbyl Enterpises Limited.
            </p>
            <p>

              2. Your Account
            </p>
            <p>
              Where use of our Services requires an account, you agree to provide us with complete and accurate information when you register for an account and keep the information current. This is important because sometimes we may need to send you notable updates (for example, about changes to our Terms of Service or Privacy Policy), or we may want to let you know about and make informed choices in response to legal inquiries or complaints.
            </p>
            <p>
              You will be solely responsible and liable for any activity that occurs under your account. You are responsible for keeping your account information up-to-date and for keeping your password secure.
            </p>
            <p>
              You are responsible for maintaining the security of your account and any Service-related website, store, or other content, and you are fully responsible for all activities that occur under your account and any other actions taken in connection with our Services. You shall not share or misuse your access credentials. You must immediately notify us of any unauthorized uses of your account, store, or website, or of any other breach of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions.
            </p>
            <p>
              When you create a Pikle.online account, we consider that to be an inquiry about our products and services, which means that we may contact you to share more details about what we have to offer. Don’t worry — if you aren’t interested in learning more, you can opt out of the marketing communication, whether it’s an email, phone call, or text message.
            </p>
            <p>

              a. Beta Program Accounts
            </p>
            <p>
              The service will be available on an invite only basis during an Beta Program. By using the service during this period you are an awesome individual who is helping to make the Service better and you are subject to a few extra clauses.
            </p>
            <p>
              During this phase Pikle can change without notification significant aspects of the service.
            </p>
            <p>
              The Beta program test period shall last until Pikle enables public registration via its website and apps. Section 2a of this agreement shall terminate at the end of the Beta test period or when Pikle Online terminates a users account during the Beta program period, whichever occurs first. The restrictions and obligations contained in all other claussess of the Agreement will survive the expiration, termination or cancellation of the Beta program, and shall continue to bind any users who use the Pikle Online Services after the Beta program period.
            </p>
            <p>
              Tester may request termination of their account at any time by emailing <a href="mailto:admin@pikle.online">admin@pikle.online</a> from the email address associated with Tester's Pikle.online account and requesting the account should be terminated.
            </p>
            <p>
              a(1). Disclaimer of Warranty
            </p>
            <p>
              The Service is currently an Beta product and its accuracy and reliability are not guaranteed. You the Tester shall not rely exclusively on the Service for any reason. Tester waives any and all claims Tester may have against Pikle.online arising out of the performance or nonperformance of the Service.
            </p>
            <p>
              THE SERVICE IS PROVIDED AS IS, AND Pikle DISCLAIMS ANY AND ALL REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, WITH RESPECT TO IT, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
            </p>
            <p></p>
            <p>
              a(2). Limitation of Liability
            </p>
            <p>
              Pikle shall not be responsible for any loss or damage to Tester or any third parties caused by the Service. Pikle SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGE, WHETHER BASED ON CONTRACT OR TORT OR ANY OTHER LEGAL THEORY, ARISING OUT OF ANY USE OF THE SERVICE OR ANY PERFORMANCE OF THIS AGREEMENT.
            </p>
            <p>
              a(3). No Rights Granted
            </p>
            <p>
              This Agreement does not constitute a grant or an intention or commitment to grant any right, title or interest in the Service or Pikle’s trade secrets to Tester. Tester may not sell or transfer any portion of the Service to any third party or use the Service in any manner to produce, market or support its own products. Tester shall not identify the Service as coming from any source other than Pikle.
            </p>
            <p>
              a(4). No Assignments
            </p>
            <p>
              This Agreement is personal to Tester. Tester shall not assign or otherwise transfer any rights or obligations under this Agreement.
            </p>
            <p>
              a(5). General Provisions
            </p>
            <p>
              (a) Relationships: Nothing contained in this Agreement shall be deemed to constitute either party a partner, joint venturer or employee of the other party for any purpose.
            </p>
            <p>
              (b) Severability: If a court finds any provision of this Agreement invalid or unenforceable, the remainder of this Agreement shall be interpreted so as to best to effect the intent of the parties.
            </p>
            <p>
              (c) Integration: This Agreement expresses the complete understanding of the parties with respect to the subject matter and supersedes all prior proposals, agreements, representations, and understandings. This Agreement may not be amended except in a writing signed by both parties.
            </p>
            <p>
              (d) Waiver: The failure to exercise any right provided in this Agreement shall not be a waiver of prior or subsequent rights.
            </p>
            <p>
              (e) Attorney Fees and Expenses: In a dispute arising out of or related to this Agreement, the prevailing party shall have the right to collect from the other party its reasonable attorney fees and costs and necessary expenditures.
            </p>
            <p>
              (f) Governing Law: This Agreement shall be governed in accordance with the laws of the New Zealand.
            </p>
            <p>
              (g) Jurisdiction: The parties consent to the exclusive jurisdiction and venue of the courts located in New Zealand in any action arising out of or relating to this Agreement. The parties waive any other venue to which either party might be entitled by domicile or otherwise.
            </p>
            <p>

              3. Minimum Age Requirements
            </p>
            <p>
              Our Services are not directed to children. Access to and use of our Services is only for those over the age of 13 (or 16 in the European Union). If you are younger than this, you may not register for or use our Services. Any person who registers as a user or provides their personal information to our Services represents that they are 13 years of age or older (or 16 years or older in the European Union).
            </p>
            <p>
              4. Responsibility of Visitors and Users
            </p>
            <p>
              We have not reviewed, and cannot review, all of the content (such as, but not limited to, text, photo, video, audio, code, computer software, items for sale, or other materials) posted to our Services by users or anyone else (“Content”) and are not responsible for any use or effects of such Content. So, for example:
            </p>
            <p>
              We do not endorse any Content or represent that Content is accurate, useful, or non-harmful. Content could be offensive, indecent, or objectionable; include technical inaccuracies, typographical mistakes, or other errors; or violate or infringe the privacy, publicity rights, intellectual property rights (see our Copyright Infringement and DMCA Policy section to submit copyright complaints), or other proprietary rights of third parties.
              If you post Content, comment on a website, or otherwise make (or allow any third party to make) Content available on our Services, you are entirely responsible for the Content, and any harm resulting from, that Content or your conduct.
              We disclaim any responsibility for any harm resulting from anyone’s use, purchase, or downloading of Content. If you access or use any Content, you are responsible for taking precautions as necessary to protect yourself and your computer systems from viruses, worms, Trojan horses, and other harmful or destructive content.
              Any Content offered for sale through any of our Services are the seller’s sole responsibility, and you agree that you will look solely to the seller for any damages that result from your purchase or use of Content.
              We are not a party to, and will have no responsibility or liability for, any communications, transactions, interactions, or disputes between you and the provider of any Content.
              Please note that additional third party terms and conditions may apply to the downloading, copying, purchase, or use of Content.
            </p>
            <p>
              We also have not reviewed, and cannot review, all of the material, including computer software, made available through the websites and web pages linked from, Pikle.online or our other Services. For example:
            </p>
            <p>
              We do not have any control over those websites and are not responsible for their contents or their use.
              The existence of a link to or from one of our Services does not represent or imply that we endorse such website.
              You are responsible for taking precautions as necessary to protect yourself and your computer systems from viruses, worms, Trojan horses, and other harmful or destructive content.
              We disclaim any responsibility for any harm resulting from non-Pikle Online websites.
            </p>
            <p>
              5. Ecommerce Console - App Market Insights
            </p>
            <p>
              App Market Insights data and reports are available for internal use of the Apps domain owner only, or those acting on the App domain owners behalf such as members of an ecommerce console App. Those acting on the App domain owners behalf must also comply with this part of the terms. In these terms “App domain owner” means the business entity owning the domain associated with the App where Market Insights are enabled.
            </p>
            <p>
              Market Insights data cannot be resold, made publicly available, or aggregated across businesses.
            </p>
            <p>
              It is your responsibility to ensure that your use, or any third party's use, of data generated by, or exported from your account complies with the terms set out here.
            </p>
            <p>
              6. Fair Use
            </p>
            <p>
              We allow our users including App domain owners and ecommerce console users to save and refresh any publicly available content online.
              The Service is a tool, similar to a browser, that can be used by you in a way that will generate traffic on any websites you visit using the Service.
              Pikle places reasonable limitations on the frequency and scale of things you can check online but it is solely your responsibility to use the Service in a way that does not negatively impact any other entity or service or break any relevant laws.
              We disclaim any responsibility for any loss or damage caused by your misuse of the Service.
            </p>
            <p>
              7. General Representation and Warranty
            </p>
            <p>
              You represent and warrant that your use of our Services:
            </p>
            <p>
              Will be in strict accordance with these Terms;
              Will comply with all applicable laws and regulations (including, without limitation, all applicable laws regarding online conduct and acceptable content, privacy, data protection, the transmission of technical data exported from the United States or the country in which you reside, the use or provision of financial services, notification and consumer protection, unfair competition, and false advertising);
              Will not use the Services for any unlawful purposes, to publish illegal content, or in furtherance of illegal activities;
              Will not infringe or misappropriate the intellectual property rights of any third party;
              Will not overburden Pikle Online’s systems, as determined by us in our sole discretion;
              Will not disclose sensitive personal information of others;
              Will not be used to send spam or bulk unsolicited messages;
              Will not interfere with, disrupt, or attack any service or network; and
              Will not be used to create, distribute, or enable material that is – or that facilitates or operates in conjunction with – malware, spyware, adware, or other malicious programs or code.
            </p>
            <p>
              8. Copyright Infringement and DMCA Policy
            </p>
            <p>
              As we ask others to respect our intellectual property rights, we respect the intellectual property rights of others. If you believe that material located on or associated with an Pikle product or service violates your copyright, please notify us in accordance with Pikle Online’s Digital Millennium Copyright Act (“DMCA”) Policy. We will respond to all such notices, including as required or appropriate by removing the infringing material or disabling all links to the infringing material. We will terminate a visitor’s access to and use of the website if, under appropriate circumstances, the visitor is determined to be a repeat infringer of the copyrights or other intellectual property rights of Pikle Online or others. In the case of such termination, we will have no obligation to provide a refund of any amounts previously paid to us.
            </p>
            <p>
              9. Intellectual Property
            </p>
            <p>
              The Agreement does not transfer from Pikle Online to you any Pikle Online or third party intellectual property, and all right, title, and interest in and to such property will remain (as between the parties) solely with Pikle Online. Pikle Online, and all other trademarks, service marks, graphics, and logos used in connection with Pikle.online or our Services, are trademarks or registered trademarks of Pikle Online or Pikle Online’s licensors. Other trademarks, service marks, graphics, and logos used in connection with our Services may be the trademarks of other third parties. Your use of our Services grants you no right or license to reproduce or otherwise use any Pikle Online or third party trademarks.
            </p>
            <p>
              10. Third Party Services
            </p>
            <p>
              In using the Services, you may enable or use services, products, software (like themes or plugins), embeds, or applications developed by a third party or yourself (“Third Party Services”) on your website.
            </p>
            <p>
              If you use any Third Party Services, you understand that:
            </p>
            <p>
                  Third Party Services are not vetted, endorsed, or controlled by Pikle Online.
                  Any use of a Third Party Service is at your own risk, and we shall not be responsible or liable to anyone for Third Party Services.
                  Your use is solely between you and the respective third party (“Third Party”) and will be governed by the Third Party’s terms and policies. It is your responsibility to review the Third Party’s terms and policies before using a Third Party Service.
                  Some Third Party Services may request or require access to your (yours, your visitors’, or customers’) data, for example, via a pixel or cookie. If you use the Third Party Service or grant access, your data will be handled in accordance with the Third Party’s privacy policy and practices. We do not have control over how a Third Party Service may use your data. You should carefully review Third Party Services’ data collection, retention, and use policies and practices before enabling Third Party Services.
                  Third Party Services may not work appropriately with your website, and we may not be able to provide support for issues caused by any Third Party Services.
                  If you have questions or concerns about how a Third Party Service operates, or need support, please contact the Third Party directly.
                </p>
                <p>
              In rare cases, we may at our discretion, suspend, disable, or remove Third Party Services from your account or website.

            </p>
            <p>
              11. Changes
            </p>
            <p>
              We are constantly updating our Services and that means sometimes we have to change the legal terms under which our Services are offered. These Terms may only be modified by a written amendment signed by an authorized executive of Pikle Online, or by the posting by Pikle Online of a revised version. If we make changes that are material, we will let you know by posting on one of our blogs, or by sending you an email or other communication before the changes take effect. The notice will designate a reasonable period of time after which the new terms will take effect. If you disagree with our changes, then you should stop using our Services within the designated notice period, or once the changes become effective. Your continued use of our Services will be subject to the new terms. However, any dispute that arose before the changes shall be governed by the Terms (including the binding individual arbitration clause) that were in place when the dispute arose.
            </p>
            <p>
              12. Termination
            </p>
            <p>
              We may terminate your access to all or any part of our Services at any time, with or without cause, with or without notice, effective immediately. We have the right (though not the obligation) to, in our sole discretion, (i) reclaim your username or website’s URL due to prolonged inactivity, (ii) refuse or remove any content that, in our reasonable opinion, violates any Pikle Online policy or is in any way harmful or objectionable, (iii) ask you to make some adjustments, restrict the resources your website uses, or terminate your Services, if we believe your website’s storage or bandwidth usage is out of hand and burdens our systems (which is rare and typically only occurs when a website is used for file sharing or storage), or (iv) terminate or deny access to and use of any of our Services to any individual or entity for any reason. We will have no obligation to provide a refund of any amounts previously paid.
            </p>
            <p>
              If you wish to terminate the Agreement or your Pikle.online account, you may simply discontinue using our Services. During the Beta program you must request the termination of your service as described in section 2a.
            </p>
            <p>
              All provisions of the Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
            </p>
            <p>
              13. Disclaimer of Warranties
            </p>
            <p>
              Our Services are provided “as is.” Pikle Online and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Neither Pikle Online, nor its suppliers and licensors, makes any warranty that our Services will be error free or that access thereto will be continuous or uninterrupted. If you’re actually reading this, here’s a treat. You understand that you download from, or otherwise obtain content or services through, our Services at your own discretion and risk.
            </p>
            <p>
              14. Jurisdiction and Applicable Law.
            </p>
            <p>
              Except to the extent any applicable law provides otherwise, the Agreement and any access to or use of our Services will be governed by the laws of New Zealand., excluding its conflict of law provisions. The proper venue for any disputes arising out of or relating to the Agreement and any access to or use of our Services will be the courts located in New Zealand.
            </p>
            <p>
              15. Arbitration Agreement
            </p>
            <p>
              Except for claims for injunctive or equitable relief or claims regarding intellectual property rights (which may be brought in any competent court without the posting of a bond), any dispute arising under the Agreement shall be finally settled through the Arbitrators' and Mediators' Institute of New Zealand in accordance with the Arbitration Act in New Zealand. The arbitration shall take place in New Zealand, in the English language and the arbitral decision may be enforced in any court.
            </p>
            <p>
              16. Limitation of Liability
            </p>
            <p>
              In no event will Pikle Online, or its suppliers, partners, or licensors, be liable with respect to any subject matter of the Agreement under any contract, negligence, strict liability or other legal or equitable theory for: (i) any special, incidental or consequential damages; (ii) the cost of procurement for substitute products or services; (iii) for interruption of use or loss or corruption of data; or (iv) for any amounts that exceed $50 or the fees paid by you to Pikle Online under the Agreement during the twelve (12) month period prior to the cause of action, whichever is greater. Pikle Online shall have no liability for any failure or delay due to matters beyond their reasonable control. The foregoing shall not apply to the extent prohibited by applicable law.
            </p>
            <p>
              17. Indemnification
            </p>
            <p>
              You agree to indemnify and hold harmless Pikle Online, its contractors, and its licensors, and their respective directors, officers, employees, and agents from and against any and all losses, liabilities, demands, damages, costs, claims, and expenses, including attorneys’ fees, arising out of or related to your use of our Services, including but not limited to your violation of the Agreement or any agreement with a provider of third-party services used in connection with the Services, Content that you post, and any ecommerce activities conducted through your or another user’s website.
            </p>
            <p>
              18. US Economic Sanctions
            </p>
            <p>
              You expressly represent and warrant that your use of our Services and/or associated services and products is not contrary to applicable U.S. Sanctions. Such use is prohibited, and we reserve the right to terminate accounts or access of those in the event of a breach of this condition.
            </p>
            <p>
              19. Translation
            </p>
            <p>
              These Terms were originally written in English. We may translate these terms into other languages. In the event of a conflict between a translated version of these Terms and the English version, the English version will control.
            </p>
            <p>
              20. Miscellaneous
            </p>
            <p>
              The Agreement constitutes the entire agreement between Pikle Online and you concerning the subject matter hereof. If any part of the Agreement is held invalid or unenforceable, that part will be construed to reflect the parties’ original intent, and the remaining portions will remain in full force and effect. A waiver by either party of any term or condition of the Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof.
            </p>
            <p>
              You may assign your rights under the Agreement to any party that consents to, and agrees to be bound by, its terms and conditions; Pikle Online may assign its rights under the Agreement without condition. The Agreement will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns.
            </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'PikleTermsOfService',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.comparison-list {
  display: flex;
  justify-content: space-around;
}
.logo-title {
  color: #1e40aa;
  text-transform: uppercase;
}
</style>
