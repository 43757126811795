// Veux types
import * as StoreCommontTypes from '../store/types/common'

const resetStore = {
  data () {
    return {
      storeModules: [
        'decisions',
        'pikleBoard',
        'user'
      ]
    }
  },
  methods: {
    resetAllStoreModules: function () {
      // Call reset for each module
      this.storeModules.forEach((module) => {
        this.$store.dispatch(`${module}/${StoreCommontTypes.ACTION_RESET_STATE}`)
      })
    }
  }
}

export default resetStore
//
