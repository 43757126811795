import { getAnalytics } from "firebase/analytics";

const ANALTYICS_UTIL = {
  analytics: null,
  init: function (app) {
    ANALTYICS_UTIL.analytics = getAnalytics(app);
  }
}

export default ANALTYICS_UTIL
